import { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Container, Grid, Icon, Input, Loader } from 'semantic-ui-react';
import { getVocabulary, searchContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import {
  DegreeProgramCard,
  DropdownWithCheckboxes,
  NavPills,
} from '@package/components';
import './search.less';

const messages = defineMessages({
  distance: {
    id: 'Distance learning',
    defaultMessage: 'Distance learning',
  },
  presence: {
    id: 'In presence',
    defaultMessage: 'In presence',
  },
  elementarySchool: {
    id: 'Elementary school',
    defaultMessage: 'Elementary school',
  },
  secondarySchoolPlus: {
    id: 'Secondary school plus',
    defaultMessage: 'Secondary school plus',
  },
  gymnasium: {
    id: 'Gymnasium',
    defaultMessage: 'Gymnasium',
  },
  vocationalSchool: {
    id: 'Vocational school',
    defaultMessage: 'Vocational school',
  },
  specialSchool: {
    id: 'Special-needs school',
    defaultMessage: 'Special-needs school',
  },
  winterSemester: {
    id: 'Winter semester',
    defaultMessage: 'Winter semester',
  },
  summerSemester: {
    id: 'Summer semester',
    defaultMessage: 'Summer semester',
  },
  findDegreeProgram: {
    id: 'Find degree program',
    defaultMessage: 'Find degree program',
  },
  all: {
    id: 'All',
    defaultMessage: 'All',
  },
  countSelected: {
    id: '{count} selected',
    defaultMessage: '{count} selected',
  },
  degree: {
    id: 'Degree',
    defaultMessage: 'Degree',
  },
  start: {
    id: 'Start',
    defaultMessage: 'Start',
  },
  format: {
    id: 'Format',
    defaultMessage: 'Format',
  },
  teaching: {
    id: 'Teaching',
    defaultMessage: 'Teaching',
  },
  faculty: {
    id: 'Faculty',
    defaultMessage: 'Faculty',
  },
  school_type: {
    id: 'School type',
    defaultMessage: 'School type',
  },
  subjects: {
    id: 'Subjects',
    defaultMessage: 'Subjects',
  },
  educational_sciences: {
    id: 'Educational Sciences',
    defaultMessage: 'Educational Sciences',
  },
  arts_and_humanities: {
    id: 'Arts / Humanities',
    defaultMessage: 'Arts / Humanities',
  },
  mathematics_natural_sciences: {
    id: 'Mathematics / Natural Sciences',
    defaultMessage: 'Mathematics / Natural Sciences',
  },
  computer_science: {
    id: 'Computer Science',
    defaultMessage: 'Computer Science',
  },
});

const FACULTIES = (intl) => [
  { token: 'fb1', title: intl.formatMessage(messages.educational_sciences) },
  { token: 'fb2', title: intl.formatMessage(messages.arts_and_humanities) },
  {
    token: 'fb3',
    title: intl.formatMessage(messages.mathematics_natural_sciences),
  },
  { token: 'fb4', title: intl.formatMessage(messages.computer_science) },
];

const DEGREES = [
  { token: 'bart', title: 'Bachelor of Arts' },
  { token: 'bed', title: 'Bachelor of Education' },
  { token: 'bsc', title: 'Bachelor of Science' },
  { token: 'mart', title: 'Master of Arts' },
  { token: 'med', title: 'Master of Education' },
  { token: 'msc', title: 'Master of Science' },
  { token: 'mba', title: 'Master of Business Administration' },
];

const PROGRAM_FORMATS = (intl) => [
  { token: 'presence', title: intl.formatMessage(messages.presence) },
  { token: 'distance', title: intl.formatMessage(messages.distance) },
];

const SCHOOL_TYPES = (intl) => [
  { token: 'elementary', title: intl.formatMessage(messages.elementarySchool) },
  {
    token: 'secondary',
    title: intl.formatMessage(messages.secondarySchoolPlus),
  },
  { token: 'gymnasium', title: intl.formatMessage(messages.gymnasium) },
  { token: 'vocational', title: intl.formatMessage(messages.vocationalSchool) },
  { token: 'special', title: intl.formatMessage(messages.specialSchool) },
];

const SEMESTERS = (intl) => [
  { token: 'wise', title: intl.formatMessage(messages.winterSemester) },
  { token: 'sose', title: intl.formatMessage(messages.summerSemester) },
];

const DegreeProgramsSearchView = ({ content }) => {
  const intersect = (a, b) => {
    const setB = new Set(b);
    return [...new Set(a)].filter((x) => setB.has(x));
  };

  const location = useLocation();

  /* parse URL parameters (after `?`) */
  const params = Object.fromEntries(
    location.search
      .slice(1)
      .split('&')
      .filter((p) => p.indexOf('=') > -1)
      .map((p) => {
        const [key, value] = p.split('=');
        return [key, value.split(',')];
      }),
  );

  const [filterDegree, setFilterDegree] = useState(params['degree'] || []);
  const [filterFaculties, setFilterFaculties] = useState(
    params['faculty'] || [],
  );
  const [filterFormat, setFilterFormat] = useState(params['format'] || []);
  const [filterSchoolTypes, setFilterSchoolTypes] = useState(
    params['school'] || [],
  );
  const [filterSemester, setFilterSemester] = useState(
    params['semester'] || [],
  );
  const [filterSubjects, setFilterSubjects] = useState(params['subject'] || []);
  const [filterTeaching, setFilterTeaching] = useState(
    params['teaching'] ? true : false,
  );
  const [filterTopics, setFilterTopics] = useState(params['topic'] || []);
  const [searchInput, setSearchInput] = useState(
    params['q']?.length > 0 ? decodeURIComponent(params['q'][0]) : '',
  );

  const resetAllFilters = () => {
    setFilterDegree([]);
    setFilterFaculties([]);
    setFilterFormat([]);
    setFilterSchoolTypes([]);
    setFilterSemester([]);
    setFilterSubjects([]);
    setFilterTeaching(false);
    setFilterTopics([]);
    setSearchInput('');
    if (typeof window !== 'undefined' && window.innerWidth < 992)
      setFiltersCollapsed(true);
  };

  const areFiltersResetable =
    filterDegree.length > 0 ||
    filterFaculties.length > 0 ||
    filterFormat.length > 0 ||
    filterSchoolTypes.length > 0 ||
    filterSemester.length > 0 ||
    filterSubjects.length > 0 ||
    filterTeaching !== false ||
    filterTopics.length > 0 ||
    searchInput.length > 0;

  const [filtersCollapsed, setFiltersCollapsed] = useState(false);
  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.innerWidth < 992 &&
      !areFiltersResetable
    )
      setFiltersCollapsed(true);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const dispatch = useDispatch();
  const intl = useIntl();
  const curLangCode = intl.locale || 'de';

  const compareItems = (a, b) => {
    const cmpA = a.title + '-' + a.degree?.map((d) => d.token).join('-');
    const cmpB = b.title + '-' + b.degree?.map((d) => d.token).join('-');
    return cmpA < cmpB ? -1 : cmpA > cmpB ? 1 : 0;
  };

  const items = useSelector(
    (state) =>
      state.search?.items
        .filter((i) => i)
        /* filter translation duplicates */
        .filter(
          (i) =>
            /* only keep items of current language ... */
            (i.language?.token || '') === curLangCode ||
            /* ... or foreign-language items that dont have a translation */
            i['@components'].translations.closests[0]['@id'].endsWith(
              '/studienangebot',
            ) ||
            i['@components'].translations.closests[0]['@id'].endsWith(
              '/degree-programs',
            ),
        )
        /* filter faculties */
        .filter(
          (i) =>
            filterFaculties.length === 0 ||
            intersect(
              (i.faculties || []).map((d) => d.token),
              filterFaculties,
            ).length > 0,
        )
        /* filter semester */
        .filter(
          (i) =>
            filterSemester.length === 0 ||
            intersect(
              (i.program_start || []).map((d) => d.token),
              filterSemester,
            ).length > 0,
        )
        /* filter degrees */
        .filter(
          (i) =>
            filterDegree.length === 0 ||
            intersect(
              i.degree.map((d) => d.token),
              filterDegree,
            ).length > 0,
        )
        /* filter formats */
        .filter(
          (i) =>
            filterFormat.length === 0 ||
            (filterFormat.indexOf('presence') > -1 &&
              (i.program_format || []).findIndex(
                (d) => d.token === 'distance',
              ) === -1) ||
            intersect(
              (i.program_format || []).map((d) => d.token),
              filterFormat,
            ).length > 0,
        )
        /* filter topics */
        .filter(
          (i) =>
            filterTopics.length === 0 ||
            intersect(i.degree_program_topics, filterTopics).length > 0,
        )
        /* filter teaching */
        .filter((i) => !filterTeaching || i.type_of_school?.length > 0)
        /* filter school types */
        .filter(
          (i) =>
            filterSchoolTypes.length === 0 ||
            intersect(
              (i.type_of_school || []).map((d) => d.token),
              filterSchoolTypes,
            ).length > 0,
        )
        /* filter subjects */
        .filter(
          (i) =>
            filterSubjects.length === 0 ||
            intersect(i.school_subjects || [], filterSubjects).length > 0,
        )
        /* filter by text */
        .filter(
          (i) =>
            searchInput.length === 0 ||
            i.title.toLowerCase().indexOf(searchInput.toLowerCase()) > -1,
        )
        .sort(compareItems) || [],
  );
  const hasLoaded = useSelector((state) => state.search?.loaded || false);

  /*
  const topics =
    useSelector(
      (state) => state.vocabularies?.degree_program_topics_vocabulary?.items,
    ) || [];
  */

  const subjects =
    useSelector(
      (state) => state.vocabularies?.school_subjects_vocabulary?.items,
    ) || [];

  let pathQuery = [];
  try {
    /* we abuse the field `preview_caption` to store path query
       (should be an array of strings)
    */
    pathQuery = JSON.parse(content.preview_caption);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  useEffect(() => {
    dispatch(
      searchContent('/', {
        portal_type: ['DegreeProgram'],
        fullobjects: true,
        b_size: 999,
        'path.query': pathQuery,
        'path.depth': 1,
      }),
    );
    dispatch(
      getVocabulary({ vocabNameOrURL: 'degree_program_topics_vocabulary' }),
    );
    dispatch(getVocabulary({ vocabNameOrURL: 'school_subjects_vocabulary' }));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch]);

  const degrees = DEGREES;
  const faculties = FACULTIES(intl);
  const formats = PROGRAM_FORMATS(intl);
  const semesters = SEMESTERS(intl);
  const schoolTypes = SCHOOL_TYPES(intl);

  const onChangeFilterFaculties = (evt, data) => {
    if (data.checked === true && filterFaculties.indexOf(data.value) === -1)
      setFilterFaculties(filterFaculties.concat([data.value]));
    if (data.checked === false && filterFaculties.indexOf(data.value) > -1)
      setFilterFaculties(filterFaculties.filter((d) => d !== data.value));
  };

  const onChangeFilterDegree = (evt, data) => {
    if (data.checked === true && filterDegree.indexOf(data.value) === -1)
      setFilterDegree(filterDegree.concat([data.value]));
    if (data.checked === false && filterDegree.indexOf(data.value) > -1)
      setFilterDegree(filterDegree.filter((d) => d !== data.value));
  };

  /*
  const onChangeFilterTopics = (evt, data) => {
    if (data.checked === true && filterTopics.indexOf(data.value) === -1)
      setFilterTopics(filterTopics.concat([data.value]));
    if (data.checked === false && filterTopics.indexOf(data.value) > -1)
      setFilterTopics(filterTopics.filter((d) => d !== data.value));
  };
  */

  const onChangeFilterFormat = (evt, data) => {
    if (data.checked === true && filterFormat.indexOf(data.value) === -1)
      setFilterFormat(filterFormat.concat([data.value]));
    if (data.checked === false && filterFormat.indexOf(data.value) > -1)
      setFilterFormat(filterFormat.filter((d) => d !== data.value));
  };

  const onChangeFilterSubjects = (evt, data) => {
    if (data.checked === true && filterSubjects.indexOf(data.value) === -1)
      setFilterSubjects(filterSubjects.concat([data.value]));
    if (data.checked === false && filterSubjects.indexOf(data.value) > -1)
      setFilterSubjects(filterSubjects.filter((d) => d !== data.value));
  };

  const onChangeFilterSchoolTypes = (evt, data) => {
    if (data.checked === true && filterSchoolTypes.indexOf(data.value) === -1)
      setFilterSchoolTypes(filterSchoolTypes.concat([data.value]));
    if (data.checked === false && filterSchoolTypes.indexOf(data.value) > -1)
      setFilterSchoolTypes(filterSchoolTypes.filter((d) => d !== data.value));
  };

  const onChangeFilterSemester = (evt, data) => {
    if (data.checked === true && filterSemester.indexOf(data.value) === -1)
      setFilterSemester(filterSemester.concat([data.value]));
    if (data.checked === false && filterSemester.indexOf(data.value) > -1)
      setFilterSemester(filterSemester.filter((d) => d !== data.value));
  };

  const onChangeTeaching = (evt, data) => {
    if (data.checked) {
      if (filterTeaching === false) setFilterTeaching(true);
    } else {
      if (filterTeaching === true) {
        setFilterTeaching(false);
        setFilterSchoolTypes([]);
        setFilterSubjects([]);
      }
    }
  };

  /* update URL params accordingly to filters */
  if (typeof window !== 'undefined') {
    let params = {};
    if (filterDegree.length > 0) params['degree'] = filterDegree.join(',');
    if (filterFaculties.length > 0)
      params['faculty'] = filterFaculties.join(',');
    if (filterFormat.length > 0) params['format'] = filterFormat.join(',');
    if (filterSchoolTypes.length > 0)
      params['school'] = filterSchoolTypes.join(',');
    if (filterSemester.length > 0)
      params['semester'] = filterSemester.join(',');
    if (filterSubjects.length > 0) params['subject'] = filterSubjects.join(',');
    if (filterTeaching) params['teaching'] = '1';
    if (filterTopics.length > 0) params['topic'] = filterTopics.join(',');
    if (searchInput.length > 0) params['q'] = encodeURIComponent(searchInput);

    const paramsStr = Object.entries(params)
      .map((e) => `${e[0]}=${e[1]}`)
      .join('&');

    const nextURL =
      paramsStr.length > 0
        ? `${location.pathname}?${paramsStr}`
        : location.pathname;
    const nextTitle = content.title;
    const nextState = { additionalInformation: 'Updated the URL with JS' };
    window.history.replaceState(nextState, nextTitle, nextURL);
  }

  return (
    <Container id="program-search-container">
      {content.relatedItems?.length > 0 && (
        <NavPills
          items={
            content.relatedItems?.length > 0
              ? content.relatedItems.map((i) => {
                  return { url: flattenToAppURL(i['@id']), title: i.title };
                })
              : []
          }
          position="right"
        />
      )}
      <h1 className="documentFirstHeading">{content.title}</h1>
      <Input
        className="in-search"
        placeholder={intl.formatMessage(messages.findDegreeProgram)}
        fluid
        icon="search"
        iconPosition="left"
        value={searchInput}
        onChange={(event) => setSearchInput(event.target.value)}
      />
      <div
        className={
          filtersCollapsed ? 'filters-wrapper collapsed' : 'filters-wrapper'
        }
      >
        <div className="filters">
          <DropdownWithCheckboxes
            title={intl.formatMessage(messages.degree)}
            items={degrees}
            onChange={onChangeFilterDegree}
            filterStatus={filterDegree}
            initialOpen={filterDegree.length > 0}
          />
          <DropdownWithCheckboxes
            title={intl.formatMessage(messages.start)}
            items={semesters}
            onChange={onChangeFilterSemester}
            filterStatus={filterSemester}
            initialOpen={filterSemester.length > 0}
          />
          <DropdownWithCheckboxes
            title={intl.formatMessage(messages.format)}
            items={formats}
            onChange={onChangeFilterFormat}
            filterStatus={filterFormat}
            initialOpen={filterFormat.length > 0}
          />
          <DropdownWithCheckboxes
            title={intl.formatMessage(messages.teaching)}
            items={[
              { title: intl.formatMessage(messages.teaching), token: 'true' },
            ]}
            onChange={onChangeTeaching}
            initialOpen={filterTeaching}
            computeChecked={() => filterTeaching}
          />
          {filterTeaching && (
            <>
              <DropdownWithCheckboxes
                title={intl.formatMessage(messages.school_type)}
                items={schoolTypes}
                onChange={onChangeFilterSchoolTypes}
                filterStatus={filterSchoolTypes}
              />
              <DropdownWithCheckboxes
                title={intl.formatMessage(messages.subjects)}
                items={subjects}
                onChange={onChangeFilterSubjects}
                filterStatus={filterSubjects}
                initialOpen={filterSubjects.length > 0}
                keyField="label"
                valueField="label"
                menuDirection="left"
              />
            </>
          )}
          <DropdownWithCheckboxes
            title={intl.formatMessage(messages.faculty)}
            items={faculties}
            onChange={onChangeFilterFaculties}
            filterStatus={filterFaculties}
            initialOpen={filterFaculties.length > 0}
            menuDirection="left"
          />
        </div>
        {areFiltersResetable && (
          <span
            onClick={() => resetAllFilters()}
            size="tiny"
            className="reset-filters"
            onKeyDown={() => resetAllFilters()}
            role="button"
            tabIndex={0}
          >
            <FormattedMessage
              id="Reset all filters"
              defaultMessage="Reset all filters"
            />
          </span>
        )}
      </div>
      <div className="results">
        {hasLoaded ? (
          <>
            <div className="tools">
              <span>
                <FormattedMessage
                  id="Degree programs"
                  defaultMessage="Degree programs"
                />{' '}
                <strong>({items.length})</strong>
              </span>
              {filtersCollapsed && (
                <span
                  className="toggle-filters"
                  onClick={() => setFiltersCollapsed(false)}
                  onKeyDown={() => setFiltersCollapsed(false)}
                  role="button"
                  tabIndex={0}
                >
                  <Icon name="filter" />
                  Filter
                </span>
              )}
            </div>
            <Grid>
              {items.map((item) => (
                <Grid.Column
                  mobile={12}
                  tablet={6}
                  computer={6}
                  largeScreen={4}
                  widescreen={4}
                  key={item['@id']}
                >
                  <DegreeProgramCard content={item} />
                </Grid.Column>
              ))}
            </Grid>
          </>
        ) : (
          <>
            <Loader size="large" inline="centered" active>
              <FormattedMessage
                id="Loading degree programs ..."
                defaultMessage="Loading degree programs ..."
              />
            </Loader>
          </>
        )}
      </div>
    </Container>
  );
};

export default DegreeProgramsSearchView;
