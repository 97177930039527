import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Container,
  Grid,
  Icon,
  List,
  Modal,
  Table,
} from 'semantic-ui-react';
import {
  getContent,
  getQueryStringResults,
  queryRelations,
} from '@plone/volto/actions';
import {
  FormattedDate,
  Icon as VoltoIcon,
  UniversalLink,
} from '@plone/volto/components';
import { Plug } from '@plone/volto/components/manage/Pluggable';
import { flattenToAppURL } from '@plone/volto/helpers';
import cx from 'classnames';
/* eslint-disable-next-line import/no-unresolved */
import { TextWithGlossaryTooltips } from '@rohberg/volto-slate-glossary/utils';
import { PreviewHero } from '@package/components';
import ToCView from '@package/components/Blocks/ToC/View';
import { FakePersonCard } from '@package/components/Views/DegreeProgram/utils';
import WorkflowQuickDial from './WorkflowQuickDial';
import CopyOfferButton from './CopyOfferButton';
import DeleteOfferButton from './DeleteOfferButton';
import placeholderJPG from '@package/assets/placeholder_project.jpg';
import placeholderWEBP from '@package/assets/placeholder_project.webp';
import '@package/components/Views/DegreeProgram/program.less';
import MedalSVG from './../../svgs/icons/medal-solid.svg';
import OffersCarousel from './OffersCarousel';
import PersonCard from './PersonCard';
import Tags from './Tags';
import './offer.less';

const CompetenceArea = ({ area, levels }) => {
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState(false);
  /* area is considered active if at least one of its competences is selected */
  const active = Object.keys(levels).find((k) => k.startsWith(`${area.id}.`));
  const openCompetenceModal = (
    competence,
    area,
    hasBadge,
    levelFrom,
    levelTo,
    criteria,
  ) => {
    setModalContent({
      header: `${competence.id} ${competence.label}`,
      subheader: area.label,
      description: competence.description,
      criteria: criteria,
      color: area.color,
      badge: hasBadge,
      from: levelFrom,
      to: levelTo,
    });
    setOpen(true);
  };
  return (
    <>
      <div
        className={cx('competence-area', { active: active })}
        style={{ color: area.color }}
      >
        {area.label}
      </div>
      {area.children.map((competence) => {
        const idx = Object.keys(levels).indexOf(competence.id);
        const level = idx > -1 ? levels[competence.id] : false;
        const selected = level;
        const levelFrom = level ? (level.from || '').toUpperCase() : false;
        const levelTo = level ? (level.to || '').toUpperCase() : false;
        const hasBadge = level ? level.badge || false : false;
        const criteria = level ? level.criteria || [] : [];
        return (
          <div
            className={cx({
              competence: true,
              unselected: !selected,
            })}
            style={{ outlineColor: area.color }}
            key={`${area.id}-${competence.id}`}
            onClick={() => {
              openCompetenceModal(
                competence,
                area,
                hasBadge,
                levelFrom,
                levelTo,
                criteria,
              );
            }}
            onKeyDown={() => {
              openCompetenceModal(
                competence,
                area,
                hasBadge,
                levelFrom,
                levelTo,
                criteria,
              );
            }}
            role="button"
            tabIndex={0}
          >
            <span className="title">
              {competence.id} {competence.label}
            </span>
            {levelFrom && (
              <span className="from">
                <Icon name="street view" />
                <span className="text">{levelFrom}</span>
              </span>
            )}
            {levelFrom && levelTo && (
              <Icon name="long arrow alternate right" size="small" />
            )}
            {levelTo && (
              <span className="to">
                <Icon name="map marker alternate" />
                <span className="text">{levelTo}</span>
              </span>
            )}
            {hasBadge && (
              <VoltoIcon
                className="badge"
                name={MedalSVG}
                title="Für diese Kompetenz kann eine anrechenbare Evidenz bescheinigt werden."
              />
            )}
          </div>
        );
      })}
      <Modal
        closeIcon
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        className="modal-competence"
        style={{ outlineColor: modalContent.color }}
      >
        <Modal.Header
          style={{
            borderColor: modalContent.color,
            color: modalContent.color,
          }}
        >
          <span className="subheader">{modalContent.subheader}</span>
          {modalContent.header}
        </Modal.Header>
        <Modal.Content style={{ borderColor: modalContent.color }}>
          <h4>
            <TextWithGlossaryTooltips text={'Definition nach DigCompEdu'} />
          </h4>
          <Modal.Description>{modalContent.description}</Modal.Description>
        </Modal.Content>
        {modalContent.from && modalContent.to && (
          <Modal.Content style={{ borderColor: modalContent.color }}>
            <h4>
              <TextWithGlossaryTooltips
                text={'Stufenaufstieg bzw. -verfestigung für diese Kompetenz'}
              />
            </h4>
            <TextWithGlossaryTooltips
              text={'In diesem Angebot wird Kompetenzstufe'}
            />{' '}
            <b>{modalContent.from}</b> vorausgesetzt
            {modalContent.from === modalContent.to ? (
              <>
                {' '}
                <TextWithGlossaryTooltips text={'und verfestigt.'} />
              </>
            ) : (
              <>
                <TextWithGlossaryTooltips
                  text={' und eine Steigerung auf Stufe'}
                />{' '}
                <b>{modalContent.to}</b>{' '}
                <TextWithGlossaryTooltips text={'ist möglich.'} />
              </>
            )}
          </Modal.Content>
        )}
        {modalContent.criteria && modalContent.criteria.length > 0 && (
          <Modal.Content
            style={{
              borderColor: modalContent.color,
              borderTop: '1px solid ' + modalContent.color,
            }}
          >
            <h4>
              <TextWithGlossaryTooltips text={'Zu erfüllende Kriterien'} />
            </h4>
            <ul>
              {modalContent.criteria.map((criterion, index) => (
                <li key={`crit-${index}`}>{criterion}</li>
              ))}
            </ul>
          </Modal.Content>
        )}
        {modalContent.badge && (
          <Modal.Content style={{ borderColor: modalContent.color }}>
            <span>
              <VoltoIcon
                className="badge"
                name={MedalSVG}
                title="Für diese Kompetenz kann eine anrechenbare Evidenz bescheinigt werden."
              />
              <TextWithGlossaryTooltips
                text={
                  'Für diese Kompetenz kann eine anrechenbare Evidenz bescheinigt werden.'
                }
              />
            </span>
          </Modal.Content>
        )}
        <Modal.Actions style={{ borderColor: modalContent.color }}>
          <Button negative onClick={() => setOpen(false)}>
            Schließen
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

const CompetenceAreas = ({ levels, updateSignPosts }) => {
  const url = '/de/digcompedu-schema';
  const subrequest = useSelector((state) => state.content.subrequests?.[url]);
  const schema = subrequest?.data?.schema?.items || false;
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      url !== false &&
      !subrequest?.loading &&
      !subrequest?.loaded &&
      !subrequest?.error
    )
      dispatch(getContent(url, null, url));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, url]);

  return (
    schema && (
      <>
        <p>In diesem Angebot werden folgende Kompetenzen angesprochen:</p>
        <i>
          Klicken oder Tippen Sie auf die Kacheln, um weitere Informationen zu
          erhalten.
        </i>
        <br />
        <br />
        <Grid doubling stackable>
          <Grid.Row columns={3} row-index="0">
            <Grid.Column>
              <CompetenceArea area={schema[0]} levels={levels} />
            </Grid.Column>
            <Grid.Column>
              <CompetenceArea area={schema[1]} levels={levels} />
            </Grid.Column>
            <Grid.Column>
              <CompetenceArea area={schema[2]} levels={levels} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={3} row-index="1">
            <Grid.Column>
              <CompetenceArea area={schema[3]} levels={levels} />
            </Grid.Column>
            <Grid.Column>
              <CompetenceArea area={schema[4]} levels={levels} />
            </Grid.Column>
            <Grid.Column>
              <CompetenceArea area={schema[5]} levels={levels} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    )
  );
};

const BasicData = ({ content }) => {
  const datesStrings = (content.offer_dates?.items || [])
    .filter((i) => i.start && i.end)
    .map((i) => {
      let dtStart = new Date(i.start);
      let dtEnd = new Date(i.end);
      const timeOptions = {
        hour: '2-digit',
        minute: '2-digit',
      };
      if (dtStart.toLocaleDateString() === dtEnd.toLocaleDateString())
        return `${dtStart.toLocaleDateString(
          'de-DE',
        )} ${dtStart.toLocaleTimeString(
          'de-DE',
          timeOptions,
        )} bis ${dtEnd.toLocaleTimeString('de-DE', timeOptions)} Uhr`;
      return `${dtStart.toLocaleDateString(
        'de-DE',
      )} ${dtStart.toLocaleTimeString(
        'de-DE',
        timeOptions,
      )} bis ${dtEnd.toLocaleDateString('de-DE')} ${dtEnd.toLocaleTimeString(
        'de-DE',
        timeOptions,
      )} Uhr`;
    });
  const metaFields = [
    {
      label: 'Institution',
      value: content.institution?.title || '',
    },
    {
      label: 'Veranstaltungsart',
      value: content.offer_kind_other || content.offer_kind?.title || '',
    },
    {
      label: 'Durchführungsformat',
      value: content.offer_format?.title || '',
    },
    {
      label: 'Sprache',
      value: content.offer_language || '',
    },
    {
      label: 'Durchführungszeitraum',
      value:
        content.period_from && content.period_to ? (
          <>
            <FormattedDate
              date={content.period_from}
              format={{ day: '2-digit', month: '2-digit', year: 'numeric' }}
            />{' '}
            bis{' '}
            <FormattedDate
              date={content.period_to}
              format={{ day: '2-digit', month: '2-digit', year: 'numeric' }}
            />
          </>
        ) : (
          ''
        ),
    },
    {
      label: 'Semester',
      value: content.semester?.title || '',
    },
    {
      label: 'Dauer der einzelnen Veranstaltung',
      value: content.duration ? content.duration + ' Minuten' : '',
    },
    {
      label: 'Arbeitsaufwand',
      value: content.workload
        ? `${content.workload} Arbeitseinheiten (entspricht ${
            content.workload * 45
          } Minuten)`
        : '',
    },
    {
      label: 'Termine',
      value:
        datesStrings.length > 0 ? (
          <List>
            {datesStrings.map((d) => (
              <List.Item>{d}</List.Item>
            ))}
          </List>
        ) : (
          ''
        ),
    },
    {
      label: 'Veranstaltungsturnus',
      value: content.offer_turnus_other || content.offer_turnus?.title || '',
    },
    {
      label: 'Max. Teilnehmendenzahl',
      value: content.participants_count || '',
    },
    {
      label: 'Zielgruppe(n)',
      value:
        content.target_group?.length > 0
          ? content.target_group.map((i) => i.title).join(', ')
          : '',
    },
    {
      label: 'Teilnahmebescheinigung',
      value: content.participation?.data?.length > 0 ? 'Ja' : 'Nein',
    },
    {
      label: 'Es wird ein Kompetenznachweis vergeben',
      value: content.creditability?.length > 0 ? 'Ja' : 'Nein',
    },
  ];
  return (
    <div>
      <div className="program-description">{content.description}</div>
      <h2 id="overview">Übersicht</h2>
      <Table striped className="facts">
        <Table.Body>
          {metaFields.map((field, index) => (
            <Table.Row key={`facts-${index}`}>
              <Table.Cell>{field.label}</Table.Cell>
              <Table.Cell>
                {typeof field.value === 'string'
                  ? field.value?.length > 0
                    ? field.value
                    : 'keine Angabe'
                  : field.value}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <br />
      <div className="field">
        <h4>
          <TextWithGlossaryTooltips
            text={
              'Weitere erforderliche Vorbereitungen / Teilnahmevoraussetzungen'
            }
          />
        </h4>
        {(content.requirements?.items || []).filter((i) => i.text?.length > 0)
          .length > 0 ? (
          <ul>
            {content.requirements.items
              .filter((i) => i.text)
              .map((i, index) => (
                <li key={`li-req-${index}`}>{i.text.toString()}</li>
              ))}
          </ul>
        ) : (
          <span>keine Angabe</span>
        )}
      </div>
      <hr className="divider fullwidth" />
      <br />
      <div>
        <h2 id="learning_outcomes">
          <TextWithGlossaryTooltips text={'Learning Outcomes'} />
        </h2>
        <span>
          {content.learning_outcomes_intro || 'Die Teilnehmenden können:'}
        </span>
        {content.learning_objectives?.items?.length > 0 ? (
          <ul>
            {content.learning_objectives.items
              .filter((i) => i.text)
              .map((i) => (
                <li>{i.text.toString()}</li>
              ))}
          </ul>
        ) : (
          <span> keine Angabe.</span>
        )}
      </div>
    </div>
  );
};

const OfferView = ({ content }) => {
  /* signposts are poups on the bottom of the view yielding to
     selected competence blocks which are currently not in viewport
  */
  const [signposts, setSignposts] = useState([]);

  const isInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top + rect.height - 60 >= 0 &&
      rect.left >= 0 &&
      rect.bottom - rect.height <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  const updateSignPosts = () => {
    if (window === undefined) return;
    if (document === undefined) return;

    /* only show signpost when competence areas are in viewport */
    const offsetFrom = document.getElementById('competences').offsetTop;
    const offsetTo = document.getElementById(
      'marker-end-competences',
    ).offsetTop;
    if (offsetFrom && offsetTo === false) return;
    if (window.scrollY < offsetFrom || window.scrollY > offsetTo) {
      setSignposts([]);
      return;
    }

    const selected = document.querySelectorAll('.competence:not(.unselected)');
    const newSignposts = [];
    selected.forEach((el) => {
      if (isInViewport(el) === false) {
        const col = el.parentElement;
        const row = col.parentElement;
        const offsetLeft =
          row.getAttribute('row-index') === '0'
            ? col.offsetLeft
            : col.offsetLeft + 50;
        const found = newSignposts.find((sp) => sp.left === offsetLeft);
        if (found) found.count += 1;
        else {
          newSignposts.push({
            color: el.style.backgroundColor,
            left: offsetLeft,
            /* children[0] is title span */
            // el.children[0].textContent,
            count: 1,
            bottom: el.getBoundingClientRect().top > 0,
          });
        }
      }
    });
    setSignposts(newSignposts);
  };

  useEffect(() => {
    window.addEventListener('scroll', updateSignPosts);

    return () => {
      window.removeEventListener('scroll', updateSignPosts);
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const subCaptionName =
    content.teacher?.title || content.teacher_external?.is_external
      ? content.teacher_external?.is_external
        ? content.teacher_external?.name || ''
        : content.teacher?.title || ''
      : 'keine Angabe';
  const subCaptionOrgUnit = content.teacher_external?.is_external
    ? content.teacher_external?.orgunit || false
    : content.institution?.title || false;
  const subCaption = subCaptionOrgUnit
    ? `${subCaptionName} - ${subCaptionOrgUnit}`
    : subCaptionName;

  /* get umbrella offers */
  const dispatch = useDispatch();
  const subRequestKey = content['@id'];
  const relationsRequest = useSelector(
    (state) => state.relations?.subrequests[subRequestKey] || false,
  );
  useEffect(() => {
    if (
      !relationsRequest?.loading &&
      !relationsRequest.loaded &&
      !relationsRequest.error
    )
      dispatch(
        queryRelations(
          null,
          false,
          subRequestKey,
          null,
          flattenToAppURL(subRequestKey),
        ),
      );
  }, [dispatch, relationsRequest, subRequestKey]);
  const umbrellaOffers = (relationsRequest?.data?.sub_offers?.items || []).map(
    (i) => i.source,
  );
  const umbrellaOffersUIDs = umbrellaOffers.map((o) => o.UID);

  /* get similar offers, if subjects are set */
  const queryRequest = useSelector(
    (state) => state.querystringsearch?.subrequests[subRequestKey] || false,
  );
  useEffect(() => {
    if (
      content.offer_keywords?.length > 0 &&
      !queryRequest?.loading &&
      !queryRequest?.loaded &&
      !queryRequest?.error
    )
      dispatch(
        getQueryStringResults(
          '/',
          {
            metadata_fields: '_all',
            b_size: 25,
            query: [
              {
                i: 'portal_type',
                o: 'plone.app.querystring.operation.selection.any',
                v: ['Offer'],
              },
              {
                i: 'offer_keywords',
                o: 'plone.app.querystring.operation.selection.any',
                v: content.offer_keywords,
              },
            ],
            sort_order: 'ascending',
            b_start: 0,
          },
          subRequestKey,
        ),
      );
  }, [dispatch, queryRequest, subRequestKey, content.offer_keywords]);

  const similarOffers = (queryRequest?.items || [])
    .filter(
      /* dont display current offer as similar */
      (i) => i.UID !== content.UID,
    )
    /* no intersection between similar offers and umbrella offers */
    .filter((i) => umbrellaOffersUIDs.indexOf(i.UID) === -1);

  const certificates = (content.certificates?.items || []).filter(
    (i) => i.text?.length > 0,
  );

  return (
    <Container id="program-container">
      <Plug pluggable="main.toolbar.top" id="workflow-quick-dial">
        <WorkflowQuickDial content={content} />
        <CopyOfferButton content={content} />
        <DeleteOfferButton content={content} />
      </Plug>
      {content.image_header ? (
        <PreviewHero
          heroImage={
            <img
              src={flattenToAppURL(content.image_header.scales.huge.download)}
              alt=""
            />
          }
          caption={content.title_long || content.title}
          subCaption={subCaption}
        />
      ) : (
        <PreviewHero
          heroImage={
            <picture>
              <source type="image/webp" srcSet={placeholderWEBP} />
              <img src={placeholderJPG} alt="" />
            </picture>
          }
          caption={content.title_long || content.title}
          subCaption={subCaption}
        />
      )}
      <div
        className="metabox"
        style={content.color ? { backgroundColor: content.color } : {}}
      >
        <div>
          <div>Semester</div>
          <div className="value">
            {content.semester?.title || 'keine Angabe'}
          </div>
        </div>
        <div>
          <div>Durchführungsformat</div>
          <div className="value">
            {content.offer_format?.title || 'keine Angabe'}
          </div>
        </div>
        <div>
          <div>Sprache</div>
          <div className="value">
            {content.offer_language?.length > 0
              ? content.offer_language.join(', ')
              : 'keine Angabe'}
          </div>
        </div>
        <div>
          <div>Arbeitsaufwand</div>
          <div className="value">
            {content.workload
              ? `${content.workload} Arbeitseinheiten\n(${
                  content.workload * 45
                } Minuten)`
              : 'keine Angabe'}
            <br />
          </div>
        </div>
        <UniversalLink href={content.link || '#'}>
          <Button>Zum Angebot</Button>
        </UniversalLink>
      </div>
      <Grid reversed="tablet computer">
        <Grid.Row>
          <Grid.Column mobile={12} tablet={3} computer={3}>
            <ToCView
              data={{ position: 'fixed' }}
              items={[
                {
                  key: 'overview',
                  text: 'Übersicht',
                  type: 'h2',
                },
                {
                  key: 'learning_outcomes',
                  text: 'Learning Outcomes',
                  type: 'h2',
                },
                {
                  key: 'competences',
                  text: 'Kompetenzen',
                  type: 'h2',
                },
                {
                  key: 'plan',
                  text: 'Planung und Durchführung',
                  type: 'h2',
                },
                {
                  key: 'contact',
                  text: 'Kontakt',
                  type: 'h2',
                },
                ...(umbrellaOffers.length > 0
                  ? [
                      {
                        key: 'umbrella_offers',
                        text:
                          umbrellaOffers.length > 1
                            ? 'Dachangebote'
                            : 'Dachangebot',
                        type: 'h2',
                      },
                    ]
                  : []),
                ...(similarOffers?.length > 0
                  ? [
                      {
                        key: 'similar_offers',
                        text: 'Ähnliche Angebote',
                        type: 'h2',
                      },
                    ]
                  : []),
              ]}
            />
          </Grid.Column>
          <Grid.Column mobile={12} tablet={9} computer={9}>
            <div className="content-wrapper">
              <BasicData content={content} />
              <hr className="divider fullwidth" />
              <h2 id="competences">
                <TextWithGlossaryTooltips text={'Kompetenzen'} />
              </h2>
              <div>
                <CompetenceAreas
                  levels={content.competences}
                  updateSignPosts={updateSignPosts}
                />
              </div>
              <span id="marker-end-competences"></span>
              <hr className="divider fullwidth" />
              <h2 id="plan">
                <TextWithGlossaryTooltips text={'Planung und Durchführung'} />
              </h2>
              <br />
              <div className="plan-section">
                {content.participation?.data?.length > 0 && (
                  <div className="field">
                    <h4>Teilnahmebescheinigung</h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: content.participation.data,
                      }}
                    ></div>
                  </div>
                )}
                {content.achievements?.data?.length > 0 && (
                  <div className="field">
                    <h4>
                      Zu erbringende Leistungen für Teilnahmebescheinigung
                    </h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: content.achievements.data,
                      }}
                    ></div>
                  </div>
                )}
                {content.curriculum?.data?.length > 0 && (
                  <div className="field">
                    <h4>Gliederung</h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: content.curriculum.data,
                      }}
                    ></div>
                  </div>
                )}
                {content.is_learning_pathway && (
                  <div className="field">
                    <h4>Lernpfad</h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: content.learning_pathway,
                      }}
                    ></div>
                  </div>
                )}
                {(content.creditability || []).length > 0 && (
                  <div className="field">
                    <h4>Kompetenznachweis</h4>
                    <div>
                      {(content.creditability || [])
                        .map((i) => i.title)
                        .join(', ')}
                    </div>
                  </div>
                )}
                {content.creditability?.length > 0 &&
                  content.creditability_description?.data?.length > 0 && (
                    <div className="field">
                      <h4>Beschreibung des Kompetenznachweises</h4>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: content.creditability_description.data,
                        }}
                      ></div>
                    </div>
                  )}
                {content.creditability?.length > 0 &&
                  content.creditability_achievements?.data?.length > 0 && (
                    <div className="field">
                      <h4>Zu erbringende Leistungen für Kompetenznachweise</h4>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: content.creditability_achievements.data,
                        }}
                      ></div>
                    </div>
                  )}
                {certificates.length > 0 && (
                  <div className="field">
                    <h4>Visualisierung der Kompetenz-Badges</h4>
                    <div className="certificates-wrapper">
                      {certificates.map((item) => (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.text,
                          }}
                        ></div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <hr className="divider fullwidth" />
              <h2 id="contact">Kontakt</h2>
              <div>
                <h4>Lehrperson</h4>
                {content.teacher?.token ? (
                  <PersonCard uid={content.teacher?.token} />
                ) : content.teacher_external?.is_external ? (
                  <FakePersonCard
                    head={content.teacher_external.name || ''}
                    subhead={content.teacher_external.orgunit || ''}
                    {...content.teacher_external}
                  />
                ) : (
                  <>
                    <span>keine Angabe</span>
                    <br />
                    <br />
                  </>
                )}
              </div>
              {content.authors?.length > 0 && (
                <div>
                  <h4>Weitere verantwortliche Personen</h4>
                  {(content.authors || []).map((i) => (
                    <PersonCard uid={i.token} key={i.token} />
                  ))}
                </div>
              )}
              <hr className="divider fullwidth" />
              {umbrellaOffers.length > 0 && (
                <>
                  <h2 id="umbrella_offers">
                    <TextWithGlossaryTooltips
                      text={
                        umbrellaOffers.length > 1
                          ? 'Dachangebote'
                          : 'Dachangebot'
                      }
                    />
                  </h2>
                  <p>
                    <i>
                      <TextWithGlossaryTooltips
                        text={
                          umbrellaOffers.length > 1
                            ? 'Dieses Angebot ist in die folgenden Dachangebote integriert. Die Dachangebote bestehen aus mehreren Teilangeboten, die in den Dachangeboten zu finden sind:'
                            : 'Dieses Angebot ist in das folgende Dachangebot integriert. Das Dachangebot besteht aus mehreren Teilangeboten, die im Dachangebot zu finden sind:'
                        }
                      />
                    </i>
                  </p>
                  {/* queryRelations returns objects with empty `image_scales`,
                      so we need to rehydrate them */}
                  <OffersCarousel
                    offers={umbrellaOffers}
                    rehydrateOffers={true}
                  />
                  <hr className="divider fullwidth" />
                </>
              )}
              {similarOffers.length > 0 && (
                <>
                  <h2 id="similar_offers">Ähnliche Angebote</h2>
                  <OffersCarousel offers={similarOffers} />
                </>
              )}
              <br />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Tags />
      <div className="signposts signposts-top">
        {signposts
          .filter((signpost) => !signpost.bottom)
          .map((signpost, index) => (
            <div
              key={`signpost-${index}`}
              className="ui bottom center popup transition visible signpost"
              style={{
                backgroundColor: signpost.color,
                left: signpost.left,
              }}
            >
              <div className="content">{signpost.count}</div>
            </div>
          ))}
      </div>
      <div className="signposts signposts-bottom">
        {signposts
          .filter((signpost) => signpost.bottom)
          .map((signpost, index) => (
            <div
              key={`signpost-${index}`}
              className="ui top center popup transition visible signpost"
              style={{
                backgroundColor: signpost.color,
                left: signpost.left,
              }}
            >
              <div className="content">{signpost.count}</div>
            </div>
          ))}
      </div>
    </Container>
  );
};

export default OfferView;
