import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Grid, Modal, Table } from 'semantic-ui-react';
import { getContent, getQueryStringResults } from '@plone/volto/actions';
import {
  FormattedDate,
  Icon as VoltoIcon,
  UniversalLink,
} from '@plone/volto/components';
import { Plug } from '@plone/volto/components/manage/Pluggable';
import { flattenToAppURL } from '@plone/volto/helpers';
import cx from 'classnames';
import FullCalendarListing from '@mbarde/volto-fullcalendar-block-original/components/manage/Blocks/Listing/FullCalendar';
/* eslint-disable-next-line import/no-unresolved */
import { TextWithGlossaryTooltips } from '@rohberg/volto-slate-glossary/utils';
import { PreviewHero } from '@package/components';
import ToCView from '@package/components/Blocks/ToC/View';
import { FakePersonCard } from '@package/components/Views/DegreeProgram/utils';
import WorkflowQuickDial from './WorkflowQuickDial';
import CopyOfferButton from './CopyOfferButton';
import DeleteOfferButton from './DeleteOfferButton';
import placeholderJPG from '@package/assets/placeholder_project.jpg';
import placeholderWEBP from '@package/assets/placeholder_project.webp';
import '@package/components/Views/DegreeProgram/program.less';
import MedalSVG from './../../svgs/icons/medal-solid.svg';
import OffersCarousel from './OffersCarousel';
import PersonCard from './PersonCard';
import Tags from './Tags';
import './offer.less';

const CompetenceArea = ({ area, levels = {} }) => {
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState(false);
  /* area is considered active if at least one of its competences is selected */
  const active = Object.keys(levels).find((k) => k.startsWith(`${area.id}.`));
  const openCompetenceModal = (
    competence,
    area,
    hasBadge,
    levelFrom,
    levelTo,
    criteria,
    offers,
  ) => {
    setModalContent({
      header: `${competence.id} ${competence.label}`,
      subheader: area.label,
      description: competence.description,
      criteria: criteria,
      color: area.color,
      badge: hasBadge,
      from: levelFrom,
      to: levelTo,
      offers: offers,
    });
    setOpen(true);
  };
  return (
    <>
      <div
        className={cx('competence-area', { active: active })}
        style={{ color: area.color }}
      >
        {area.label}
      </div>
      {area.children.map((competence) => {
        const idx = Object.keys(levels).indexOf(competence.id);
        const level = idx > -1 ? levels[competence.id] : false;
        const selected = level;
        const levelFrom = level ? (level.from || '').toUpperCase() : false;
        const levelTo = level ? (level.to || '').toUpperCase() : false;
        const hasBadge = level ? level.badge || false : false;
        const criteria = level ? level.criteria || [] : [];
        return (
          <div
            className={cx({
              competence: true,
              unselected: !selected,
            })}
            style={{ outlineColor: area.color }}
            key={`${area.id}-${competence.id}`}
            onClick={() => {
              openCompetenceModal(
                competence,
                area,
                hasBadge,
                levelFrom,
                levelTo,
                criteria,
                level.offers || [],
              );
            }}
            onKeyDown={() => {
              openCompetenceModal(
                competence,
                area,
                hasBadge,
                levelFrom,
                levelTo,
                criteria,
              );
            }}
            role="button"
            tabIndex={0}
          >
            <span className="title">
              {competence.id} {competence.label}
            </span>
            {hasBadge && (
              <VoltoIcon
                className="badge"
                name={MedalSVG}
                title="Für diese Kompetenz kann eine anrechenbare Evidenz bescheinigt werden."
              />
            )}
          </div>
        );
      })}
      <Modal
        closeIcon
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        className="modal-competence"
        style={{ outlineColor: modalContent.color }}
      >
        <Modal.Header
          style={{
            borderColor: modalContent.color,
            color: modalContent.color,
          }}
        >
          <span className="subheader">{modalContent.subheader}</span>
          {modalContent.header}
        </Modal.Header>
        <Modal.Content style={{ borderColor: modalContent.color }}>
          <h4>
            <TextWithGlossaryTooltips text={'Definition nach DigCompEdu'} />
          </h4>
          <Modal.Description>{modalContent.description}</Modal.Description>
        </Modal.Content>
        {modalContent.badge && (
          <Modal.Content style={{ borderColor: modalContent.color }}>
            <span>
              <VoltoIcon
                className="badge"
                name={MedalSVG}
                title="Für diese Kompetenz kann eine anrechenbare Evidenz bescheinigt werden."
              />
              <TextWithGlossaryTooltips
                text={
                  'Für diese Kompetenz kann eine anrechenbare Evidenz bescheinigt werden.'
                }
              />
            </span>
          </Modal.Content>
        )}
        {modalContent.offers?.length > 0 && (
          <Modal.Content>
            <strong>
              Diese Kompetenz wird in den folgenden Teilangeboten angesprochen:
            </strong>
            <ul>
              {modalContent.offers.map((offer) => (
                <li>{offer.title}</li>
              ))}
            </ul>
          </Modal.Content>
        )}
        <Modal.Actions style={{ borderColor: modalContent.color }}>
          <Button negative onClick={() => setOpen(false)}>
            Schließen
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

const CompetenceAreas = ({ levels, updateSignPosts }) => {
  const url = '/de/digcompedu-schema';
  const subrequest = useSelector((state) => state.content.subrequests?.[url]);
  const schema = subrequest?.data?.schema?.items || false;
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      url !== false &&
      !subrequest?.loading &&
      !subrequest?.loaded &&
      !subrequest?.error
    )
      dispatch(getContent(url, null, url));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, url]);

  return (
    schema && (
      <>
        <p>In diesem Angebot werden folgende Kompetenzen angesprochen:</p>
        <i>
          Klicken oder Tippen Sie auf die Kacheln, um weitere Informationen zu
          erhalten. Kompetenzstufen und Kriterien entnehmen Sie bitte den
          zugeordneten Teilangeboten.
        </i>
        <br />
        <br />
        <Grid doubling stackable>
          <Grid.Row columns={3} row-index="0">
            <Grid.Column>
              <CompetenceArea area={schema[0]} levels={levels} />
            </Grid.Column>
            <Grid.Column>
              <CompetenceArea area={schema[1]} levels={levels} />
            </Grid.Column>
            <Grid.Column>
              <CompetenceArea area={schema[2]} levels={levels} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={3} row-index="1">
            <Grid.Column>
              <CompetenceArea area={schema[3]} levels={levels} />
            </Grid.Column>
            <Grid.Column>
              <CompetenceArea area={schema[4]} levels={levels} />
            </Grid.Column>
            <Grid.Column>
              <CompetenceArea area={schema[5]} levels={levels} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    )
  );
};

const BasicData = ({ content }) => {
  const periodFroms = content.sub_offers
    .filter((so) => so && so.period_from)
    .map((so) => so.period_from)
    .filter((d) => d !== 'None')
    .sort();
  const periodTos = content.sub_offers
    .filter((so) => so && so.period_to)
    .map((so) => so.period_to)
    .filter((d) => d !== 'None')
    .sort();
  const metaFields = [
    {
      label: 'Institution',
      value: content.institution?.title || '',
    },
    {
      label: 'Durchführungsformat',
      value: content.offer_format?.title || '',
    },
    {
      label: 'Sprache',
      value: content.offer_language || '',
    },
    {
      label: 'Durchführungszeitraum',
      value:
        periodFroms.length > 0 && periodTos.length > 0 ? (
          <>
            <FormattedDate
              date={periodFroms[0]}
              format={{ day: '2-digit', month: '2-digit', year: 'numeric' }}
            />{' '}
            bis{' '}
            <FormattedDate
              date={periodTos.pop()}
              format={{ day: '2-digit', month: '2-digit', year: 'numeric' }}
            />
          </>
        ) : (
          ''
        ),
    },
    {
      label: 'Semester',
      value: content.semester?.title || '',
    },
    {
      label: 'Veranstaltungsturnus',
      value: content.offer_turnus_other || content.offer_turnus?.title || '',
    },
    {
      label: 'Zielgruppe(n)',
      value:
        content.target_group?.length > 0
          ? content.target_group.map((i) => i.title).join(', ')
          : '',
    },
    {
      label: 'Lernpfad-Angebot',
      value: content.is_learning_pathway ? 'Ja' : 'Nein',
    },
    {
      label: 'Teilnahmebescheinigung',
      value: content.participation?.data?.length > 0 ? 'Ja' : 'Nein',
    },
    {
      label: 'Es wird ein Kompetenznachweis vergeben',
      value: content.creditability?.length > 0 ? 'Ja' : 'Nein',
    },
  ];

  const subOffersDates = content.sub_offers
    .filter((so) => so && so.offer_dates)
    .map((so) =>
      so.offer_dates.items
        .filter((od) => od.start && od.end)
        .map((od) => {
          return {
            '@id': so['@id'],
            title: so.title,
            description: so.description,
            start: od.start,
            end: od.end,
          };
        }),
    )
    .flat()
    .sort((e1, e2) => e2.start < e1.start);

  return (
    <div>
      <div className="program-description">{content.description}</div>
      <h2 id="overview">Übersicht</h2>
      <Table striped className="facts">
        <Table.Body>
          {metaFields.map((field, index) => (
            <Table.Row key={`facts-${index}`}>
              <Table.Cell>{field.label}</Table.Cell>
              <Table.Cell>
                {typeof field.value === 'string'
                  ? field.value?.length > 0
                    ? field.value
                    : 'keine Angabe'
                  : field.value}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <br />

      <div className="field">
        <h4>
          <TextWithGlossaryTooltips
            text={
              'Weitere erforderliche Vorbereitungen / Teilnahmevoraussetzungen'
            }
          />
        </h4>
        {content.requirements?.items?.length > 0 ? (
          <ul>
            {content.requirements.items
              .filter((i) => i.text)
              .map((i, index) => (
                <li key={`li-req-${index}`}>{i.text.toString()}</li>
              ))}
          </ul>
        ) : (
          <span>keine Angabe</span>
        )}
      </div>

      <hr className="divider fullwidth" />
      {subOffersDates?.length > 0 && (
        <>
          <h2 id="dates">Termine</h2>
          <FullCalendarListing
            items={subOffersDates}
            initial_view="listMonth"
            toolbar_center={['title']}
            toolbar_left={['dayGridMonth', 'listMonth']}
            toolbar_right={['prev', 'today', 'next']}
            title_format_month="long"
            title_format_year="numeric"
            initial_date={subOffersDates[0].start}
          />
          <hr className="divider fullwidth" />
        </>
      )}
      <div>
        <h2 id="learning_outcomes">
          <TextWithGlossaryTooltips text={'Learning Outcomes'} />
        </h2>
        {content.learning_outcomes_in_sub_offers === true ? (
          <TextWithGlossaryTooltips
            text={'Learning Outcomes sind in den Teilangeboten angegeben.'}
          />
        ) : (
          <>
            <span>
              {content.learning_outcomes_intro || 'Die Teilnehmenden können:'}
            </span>
            {content.learning_objectives?.items?.length > 0 ? (
              <ul>
                {content.learning_objectives.items
                  .filter((i) => i.text)
                  .map((i) => (
                    <li>{i.text.toString()}</li>
                  ))}
              </ul>
            ) : (
              <span> keine Angabe.</span>
            )}
          </>
        )}
        <hr className="divider fullwidth" />
      </div>
    </div>
  );
};

const UmbrellaOfferView = ({ content }) => {
  /* signposts are poups on the bottom of the view yielding to
     selected competence blocks which are currently not in viewport
  */
  const [signposts, setSignposts] = useState([]);

  const isInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top + rect.height - 60 >= 0 &&
      rect.left >= 0 &&
      rect.bottom - rect.height <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  const updateSignPosts = () => {
    if (window === undefined) return;
    if (document === undefined) return;

    /* only show signpost when competence areas are in viewport */
    const offsetFrom = document.getElementById('competences').offsetTop;
    const offsetTo = document.getElementById(
      'marker-end-competences',
    ).offsetTop;
    if (offsetFrom && offsetTo === false) return;
    if (window.scrollY < offsetFrom || window.scrollY > offsetTo) {
      setSignposts([]);
      return;
    }

    const selected = document.querySelectorAll('.competence:not(.unselected)');
    const newSignposts = [];
    selected.forEach((el) => {
      if (isInViewport(el) === false) {
        const col = el.parentElement;
        const row = col.parentElement;
        const offsetLeft =
          row.getAttribute('row-index') === '0'
            ? col.offsetLeft
            : col.offsetLeft + 50;
        const found = newSignposts.find((sp) => sp.left === offsetLeft);
        if (found) found.count += 1;
        else {
          newSignposts.push({
            color: el.style.backgroundColor,
            left: offsetLeft,
            /* children[0] is title span */
            // el.children[0].textContent,
            count: 1,
            bottom: el.getBoundingClientRect().top > 0,
          });
        }
      }
    });
    setSignposts(newSignposts);
  };

  useEffect(() => {
    window.addEventListener('scroll', updateSignPosts);

    return () => {
      window.removeEventListener('scroll', updateSignPosts);
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const subCaptionName =
    content.teacher?.title || content.teacher_external?.is_external
      ? content.teacher_external?.is_external
        ? content.teacher_external?.name || ''
        : content.teacher?.title || ''
      : 'keine Angabe';
  const subCaptionOrgUnit = content.teacher_external?.is_external
    ? content.teacher_external?.orgunit || false
    : content.institution?.title || false;
  const subCaption = subCaptionOrgUnit
    ? `${subCaptionName} - ${subCaptionOrgUnit}`
    : subCaptionName;

  const dispatch = useDispatch();
  const subRequestKey = content['@id'];

  /* get similar offers, if subjects are set */
  const queryRequest = useSelector(
    (state) => state.querystringsearch?.subrequests[subRequestKey] || false,
  );
  useEffect(() => {
    if (
      content.offer_keywords?.length > 0 &&
      !queryRequest?.loading &&
      !queryRequest?.loaded &&
      !queryRequest?.error
    )
      dispatch(
        getQueryStringResults(
          '/',
          {
            metadata_fields: '_all',
            b_size: 25,
            query: [
              {
                i: 'portal_type',
                o: 'plone.app.querystring.operation.selection.any',
                v: ['Offer'],
              },
              {
                i: 'offer_keywords',
                o: 'plone.app.querystring.operation.selection.any',
                v: content.offer_keywords,
              },
            ],
            sort_order: 'ascending',
            b_start: 0,
          },
          subRequestKey,
        ),
      );
  }, [dispatch, queryRequest, subRequestKey, content.offer_keywords]);
  const subOffersIds = (content.sub_offers || [])
    .filter((o) => o && o['@id'])
    .map((o) => flattenToAppURL(o['@id']));
  const similarOffers = (queryRequest?.items || [])
    .filter(
      /* dont display current offer as similar */
      (i) => i.UID !== content.UID,
    )
    /* no intersection between similar offers and sub offers */
    .filter((i) => subOffersIds.indexOf(flattenToAppURL(i['@id'])) === -1);

  let subOffersCompetencies = {};
  content.sub_offers
    .filter((o) => o && o['@id'])
    .forEach((so) => {
      Object.keys(so.competences || {}).forEach((key) => {
        if (subOffersCompetencies.hasOwnProperty(key)) {
          if (so.competences[key].badge)
            subOffersCompetencies[key].badge = true;
          subOffersCompetencies[key].offers.push(so);
        } else {
          subOffersCompetencies[key] = {
            badge: so.competences[key].badge || false,
            offers: [so],
          };
        }
      });
    });

  const certificates = (content.certificates?.items || []).filter(
    (i) => i.text?.length > 0,
  );

  return (
    <Container id="program-container">
      <Plug pluggable="main.toolbar.top" id="workflow-quick-dial">
        <WorkflowQuickDial content={content} />
        <CopyOfferButton content={content} />
        <DeleteOfferButton content={content} />
      </Plug>
      {content.image_header ? (
        <PreviewHero
          heroImage={
            <img
              src={flattenToAppURL(content.image_header.scales.huge.download)}
              alt=""
            />
          }
          caption={content.title_long || content.title}
          subCaption={subCaption}
        />
      ) : (
        <PreviewHero
          heroImage={
            <picture>
              <source type="image/webp" srcSet={placeholderWEBP} />
              <img src={placeholderJPG} alt="" />
            </picture>
          }
          caption={content.title_long || content.title}
          subCaption={subCaption}
        />
      )}
      <div
        className="metabox"
        style={content.color ? { backgroundColor: content.color } : {}}
      >
        <div>
          <div>Semester</div>
          <div className="value">
            {content.semester?.title || 'keine Angabe'}
          </div>
        </div>
        <div>
          <div>Durchführungsformat</div>
          <div className="value">
            {content.offer_format?.title || 'keine Angabe'}
          </div>
        </div>
        <div>
          <div>Sprache</div>
          <div className="value">
            {content.offer_language?.length > 0
              ? content.offer_language.join(', ')
              : 'keine Angabe'}
          </div>
        </div>
        <div>
          <div>Teilangebote</div>
          <div className="value">{(content.sub_offers || []).length}</div>
        </div>
        <UniversalLink href={content.link || '#'}>
          <Button>Zum Angebot</Button>
        </UniversalLink>
      </div>
      <Grid reversed="tablet computer">
        <Grid.Row>
          <Grid.Column mobile={12} tablet={3} computer={3}>
            <ToCView
              data={{ position: 'fixed' }}
              items={[
                {
                  key: 'overview',
                  text: 'Übersicht',
                  type: 'h2',
                },
                {
                  key: 'dates',
                  text: 'Termine',
                  type: 'h2',
                },
                {
                  key: 'learning_outcomes',
                  text: 'Learning Outcomes',
                  type: 'h2',
                },
                {
                  key: 'competences',
                  text: 'Kompetenzen',
                  type: 'h2',
                },
                {
                  key: 'plan',
                  text: 'Planung und Durchführung',
                  type: 'h2',
                },
                {
                  key: 'sub_offers',
                  text: 'Teilangebote',
                  type: 'h2',
                },
                {
                  key: 'contact',
                  text: 'Kontakt',
                  type: 'h2',
                },
                ...(similarOffers?.length > 0
                  ? [
                      {
                        key: 'similar_offers',
                        text: 'Ähnliche Angebote',
                        type: 'h2',
                      },
                    ]
                  : []),
              ]}
            />
          </Grid.Column>
          <Grid.Column mobile={12} tablet={9} computer={9}>
            <div className="content-wrapper">
              <BasicData content={content} />
              <h2 id="competences">
                <TextWithGlossaryTooltips text={'Kompetenzen'} />
              </h2>
              <div>
                <CompetenceAreas
                  levels={subOffersCompetencies}
                  updateSignPosts={updateSignPosts}
                />
              </div>
              <span id="marker-end-competences"></span>
              <hr className="divider fullwidth" />
              <h2 id="plan">
                <TextWithGlossaryTooltips text={'Planung und Durchführung'} />
              </h2>
              <br />
              <div className="plan-section">
                {content.participation?.data?.length > 0 && (
                  <div className="field">
                    <h4>Teilnahmebescheinigung</h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: content.participation.data,
                      }}
                    ></div>
                  </div>
                )}
                {content.achievements?.data?.length > 0 && (
                  <div className="field">
                    <h4>
                      Zu erbringende Leistungen für Teilnahmebescheinigung
                    </h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: content.achievements.data,
                      }}
                    ></div>
                  </div>
                )}
                {content.is_learning_pathway && (
                  <div className="field">
                    <h4>Lernpfad</h4>
                    <div>Dies ist ein Lernpfadangebot.</div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: content.learning_pathway,
                      }}
                    ></div>
                  </div>
                )}
                {content.is_learning_pathway &&
                  content.curriculum?.data?.length > 0 && (
                    <div className="field" style={{ marginLeft: '20px' }}>
                      <b>Verlaufsempfehlung:</b>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: content.curriculum.data,
                        }}
                      ></div>
                    </div>
                  )}
                {(content.creditability || []).length > 0 && (
                  <div className="field">
                    <h4>Kompetenznachweis</h4>
                    <div>
                      {(content.creditability || [])
                        .map((i) => i.title)
                        .join(', ')}
                    </div>
                  </div>
                )}
                {content.creditability?.length > 0 &&
                  content.creditability_description?.data?.length > 0 && (
                    <div className="field">
                      <h4>Beschreibung des Kompetenznachweises</h4>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: content.creditability_description.data,
                        }}
                      ></div>
                    </div>
                  )}
                {content.creditability?.length > 0 &&
                  content.creditability_achievements?.data?.length > 0 && (
                    <div className="field">
                      <h4>Zu erbringende Leistungen für Kompetenznachweise</h4>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: content.creditability_achievements.data,
                        }}
                      ></div>
                    </div>
                  )}
                {certificates.length > 0 && (
                  <div className="field">
                    <h4>Visualisierung der Kompetenz-Badges</h4>
                    <div className="certificates-wrapper">
                      {certificates.map((item) => (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.text,
                          }}
                        ></div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <hr className="divider fullwidth" />
              <h2 id="sub_offers">
                <TextWithGlossaryTooltips text={'Teilangebote'} />
              </h2>
              <p>
                <i>
                  <TextWithGlossaryTooltips
                    text={
                      'Dies ist ein Dachangebot, das sich aus folgenden Teilangeboten zusammensetzt:'
                    }
                  />
                </i>
              </p>
              <OffersCarousel
                offers={content.sub_offers.filter((so) => so && so['@id'])}
                rehydrateOffers={true}
              />
              <hr className="divider fullwidth" />
              <h2 id="contact">Kontakt</h2>
              <br />
              <div>
                <h4>Verantwortliche Person</h4>
                {content.teacher?.token ? (
                  <PersonCard uid={content.teacher?.token} />
                ) : content.teacher_external?.is_external ? (
                  <FakePersonCard
                    head={content.teacher_external.name || ''}
                    subhead={content.teacher_external.orgunit || ''}
                    {...content.teacher_external}
                  />
                ) : (
                  <>
                    <span>keine Angabe</span>
                    <br />
                    <br />
                  </>
                )}
                <br />
              </div>
              {content.authors?.length > 0 && (
                <div>
                  <h4>Weitere verantwortliche Personen</h4>
                  {(content.authors || []).map((i) => (
                    <PersonCard uid={i.token} key={i.token} />
                  ))}
                </div>
              )}
              <hr className="divider fullwidth" />
              {similarOffers.length > 0 && (
                <>
                  <h2 id="similar_offers">Ähnliche Angebote</h2>
                  <OffersCarousel offers={similarOffers} />
                </>
              )}
              <br />
              <br />
              <br />
              <br />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Tags />
      <div className="signposts signposts-top">
        {signposts
          .filter((signpost) => !signpost.bottom)
          .map((signpost, index) => (
            <div
              key={`signpost-${index}`}
              className="ui bottom center popup transition visible signpost"
              style={{
                backgroundColor: signpost.color,
                left: signpost.left,
              }}
            >
              <div className="content">{signpost.count}</div>
            </div>
          ))}
      </div>
      <div className="signposts signposts-bottom">
        {signposts
          .filter((signpost) => signpost.bottom)
          .map((signpost, index) => (
            <div
              key={`signpost-${index}`}
              className="ui top center popup transition visible signpost"
              style={{
                backgroundColor: signpost.color,
                left: signpost.left,
              }}
            >
              <div className="content">{signpost.count}</div>
            </div>
          ))}
      </div>
    </Container>
  );
};

export default UmbrellaOfferView;
